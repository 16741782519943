import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo"
import PageTransitionContainer from "../components/PageTransitionContainer"
import { navigate } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faForward, faHome, faRedo } from "@fortawesome/free-solid-svg-icons"
import "../styles/pages/apply-now.css"
import Tilt from "react-tilt"

const ApplyNow = (props) => {
  return (
    <PageTransitionContainer>
      <Layout location={props.location} page="apply-now">
        <Seo
          title="Apply Now | Mortgage | Ratelock"
          location={props.location}
        />
        <h2 style={{ marginBottom: "20px" }}>
          What type of mortgage are you looking for? Click below to apply now.
        </h2>
        <div className="apply-now">
          <div className="main-cta">
            <div className="button-block">
              <Tilt className="Tilt" options={{ max: 5 }}>
                <button className="btn" onClick={() => navigate("/buy-a-home")}>
                  <FontAwesomeIcon icon={faHome} />
                  Buying A Home
                </button>
              </Tilt>
              <Tilt className="Tilt" options={{ max: 5 }}>
                <button className="btn" onClick={() => navigate("/renew")}>
                  <FontAwesomeIcon icon={faRedo} />
                  Renew Mortgage
                </button>
              </Tilt>
              <Tilt className="Tilt" options={{ max: 5 }}>
                <button className="btn" onClick={() => navigate("/refinance")}>
                  <FontAwesomeIcon icon={faForward} />
                  Refinance Mortgage
                </button>
              </Tilt>
            </div>
            <p>
              <strong>
                * Get the most accurate rates analyzed from over 40 lenders.
                Apply Now
              </strong>
            </p>
          </div>
        </div>
      </Layout>
    </PageTransitionContainer>
  )
}

export default ApplyNow
